$(document).ready(function () {
    const headings = document.querySelectorAll('.homepage-heading-title');

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const target = entry.target;

            if (entry.isIntersecting) {
                target.classList.add('underline-animation');
                return;
            }
            target.classList.remove('underline-animation');
        });
    });

    for (let i = 0; i < headings.length; i++) {
        observer.observe(headings[i]);
    }
});
